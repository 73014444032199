.wpTeachingPlanForm{
    /* margin: 8px auto; */
    /* padding: 20px 40px; */
    /* max-width: 505px; */
    width: 100%;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    /* min-height: 80vh; */
}
.wpTeachingPlanContent{
    /* margin: 8px auto!important; */
    /* max-width: 505px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-width: 292px; */
}
.wpTeachingPlanContent>section{
    margin: 13px auto 8px;
    max-width: 460px;
    width: 94%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.wpTeachingPlanOuterSection{
    width: 100%;
    max-width: 1024px!important;
    display: flex;
    flex-direction: row!important;
    flex-wrap: wrap;
    justify-content: center;
}
.wpTeachingPlanOuterSectionAddClass{
    margin: 0 auto!important;
}
.wpTeachingPlanSection{
    width: 100%;
    /* padding: 0 40px; */
}
.wpTeachingPlanBtnSection{
    flex-direction: row!important;
    max-width: fit-content!important;
}
.wpTeachingPlanSectionTitle{
    padding-top: 15px;
}
.wpTeachingPlanSectionCourseIdName .wpTeachingPlanFormItem{
    margin-bottom: 1rem!important;
}
.wpTeachingPlanSectionCourseIdName .wpTeachingPlanDivNameAndAddBtn  .wpTeachingPlanFormItem{
    margin-bottom: 0!important;
}
.wpTeachingPlanSectionAddAbility, .wpTeachingPlanSectionAddItem, .wpTeachingPlanSectionAddActivity{
    max-width: 460px;
    padding: 10px 20px;
}
.wpTeachingPlanDivAbility, .wpTeachingPlanDivItem, .wpTeachingPlanDivActivity{
    padding: 15px 20px;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    background-color: #FAFAFA;
}
/* .wpTeachingPlanSection:last-child{
    padding-bottom: 20px;
} */
.wpTeachingPlanFormItem{
    width: 100%;
    max-width: 540px;
    /* margin: auto!important; */
    margin-bottom: 1rem!important;
    display: flex;
    align-items: center;
}
.wpTeachingPlanDivAbility .wpTeachingPlanFormItem, .wpTeachingPlanDivItem .wpTeachingPlanFormItem, .wpTeachingPlanDivActivity .wpTeachingPlanFormItem{
    margin-bottom: 0.6rem!important;
}
.wpTeachingPlanFormItemCompensate, .wpTeachingPlanFormItemName, .wpTeachingPlanFormItemAddClass{
    margin: 0!important;
}
.wpTeachingPlanFormItemCompensate, .wpTeachingPlanFormItemName{
    /* margin-right: 8px!important; */
    flex: 5;
    display: flex;
    align-items: center;
}
.wpTeachingPlanFormItemAddClass{
    flex: 3;
}
.wpTeachingPlanFormItemSendBtn{
    margin: auto!important;
}
.wpTeachingPlanH4 {
    font-weight: 600;
    /* margin-bottom: .6rem; */
    margin-bottom: 0;
    width: 100%;
    max-width: 540px;
    /* min-width: 370px; */
}
.wpTeachingPlanH6 {
    /* margin: 1px 0 8px; */
    margin: 0 8px 0 0;
    flex: 2;
    /* min-width: 95px; */
    text-align: left;
}
.wpTeachingPlanDivNameAndAddBtn{
    width: 100%;
    /* margin-bottom: 1rem; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.wpTeachingPlanSpaceSendBtn{
    flex: 9;
    width: 100%;
    margin-top: .5rem;
    /* margin-bottom: 0.3rem; */
    /* min-width: 370px; */
    /* padding-right: 10px; */
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.wpTeachingPlanSpaceUpdateBtn{
    margin: 8px;
}
.wpTeachingPlanSpaceUpdateBtnText div{
    font-size: 14px!important;
}
/* .wpTeachingPlanSpaceSendBtn button{
    height: initial;
}
.wpTeachingPlanSpaceSendBtn button div{
    font-size: initial;
} */
.wpTeachingPlanSpaceSendBtnAdd{
    margin-top: 0;
    margin-bottom: 0;
    align-items: flex-start;
}
/* .wpTeachingPlanSpaceSendBtnAdd button div{
    font-size: 14px;
    padding: 2px 1px;
} */
.wpTeachingPlanOuterDivInput{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.wpTeachingPlanSpaceInput{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.wpTeachingPlanSpaceInputFormal{
    flex-wrap: nowrap;
}
.wpTeachingPlanInputFormal{
    margin-right: 8px;
}
.wpTeachingPlanDivDeleteOutlined{
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.wpTeachingPlanDelIcon{
    cursor: pointer;
    margin: 2px 1px 1px 5px!important;
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#F5F5F5;
    background-color:#1677FF;
}
.wpTeachingPlanDelIcon:hover{
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#1677FF;
    background-color:#F5F5F5;
}
/* .wpTeachingPlanSpaceInputName{
    flex-wrap: wrap;
    align-items: initial;
} */
/* .wpTeachingPlanDivInput{
    display: flex;
    flex-wrap: wrap;
} */
.wpTeachingPlanInput{
    width: 324px;
    min-width: 150px;
    padding: 4px 15px;
}
.wpTeachingPlanInputCategory{
    margin-top: 8px;
    width: 252px;
    min-width: 252px;
    padding: 4px 15px;
}
.wpTeachingPlanInputName{
    width: 340px;
    min-width: 250px;
    padding: 4px 15px;
}
.wpTeachingPlanInputKidCount{
    width: 80px;
    min-width: 50px;
    padding: 4px 15px;
    border-radius: 5px;
}
.wpTeachingPlanInputPeriod{
    width: 80px;
    min-width: 80px;
    padding: 4px 0;
    border-radius: 5px;
}
.wpTeachingPlanInputItem{
    width: 252px;
    min-width: 252px;
    padding: 4px 15px;
    border-radius: 5px;
}
.wpTeachingPlanInputIntro{
    width: 252px;
    min-width: 252px;
    padding: 4px 15px;
}
.wpTeachingPlanInputIntro.wpTeachingPlanInputIntroUpdate{
    width: 100%;
}
.wpTeachingPlanInputPrice{
    width: 120px;
    min-width: 120px;
    padding: 4px 15px;
}
.wpTeachingPlanInputPriceUpdate{
    max-width: 180px;
}
.wpTeachingPlanInputPlace{
    width: 242px;
    min-width: 242px;
    padding: 4px 15px;
}
.wpTeachingPlanInputMaximum{
    width: 80px;
    min-width: 80px;
    padding: 4px 15px;
}
.wpTeachingPlanSpaceDropdown{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
/* .wpTeachingPlanSpaceDropdown h6{
    margin-bottom: 1px!important;
} */
.wpTeachingPlanDivTime{
    display: flex;
    flex: 9;
    margin-top: 1px;
}
.wpTeachingPlanFlex1{
    flex: 2;
    text-align: left;
}
.wpTeachingPlanFlex2{
    flex: 9;
    text-align: left;
}
.wpTeachingPlanSpaceTime{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.wpTeachingPlanRadioGroup{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
}
.wpTeachingPlanRadio{
    display: flex!important;
    flex-wrap: nowrap;
    align-items: center;
}
.wpTeachingPlanRadioText{
    display: flex;
    align-items: center;
    margin-right: 8px;
}
.wpTeachingPlanRadioSchedule{
    display: flex!important;
    align-items: center;
}
.wpTeachingPlanRadioSchedule span{
    display: flex;
    flex-wrap: wrap;
}
.wpTeachingPlanDivTimePicker{
    display: flex;
    flex-wrap: wrap;
}
.wpTeachingPlanDatePicker{
    /* margin-right: 8px; */
    width: 150px;
    min-width: 150px;
    padding: 4px 15px;
}
.wpTeachingPlanTimePicker{
    /* margin-right: 8px; */
    width: 242px;
    min-width: 242px;
    padding: 4px 15px;
}
.wpTeachingPlanFormItemAdvanceSetting{
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.wpTeachingPlanSpaceAdvanceSetting{
    display: flex;
    flex: 9;
    text-align: left;
    margin-top: 1px;
}
.wpTeachingPlanSpaceCheckbox{
    display: flex;
}
.wpTeachingPlanSpaceCheckBoxInputNumber{
    margin: 0 0 4px 24px;
}
.wpTeachingPlanFormItemLineCastInterface{
    width: 100%;
}
.wpTeachingPlanSpaceLineCastInterface{
    width: 100%;
}
.wpTeachingPlanSpaceLineCastInterface > div{
    width: 100%;
}
.wpTeachingPlanFilterConsultContent{
    margin: 0.8rem 0 2em!important;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
}
/* .wpTeachingPlanFilterConsultContent h1, .wpTeachingPlanFilterConsultContent h2, .wpTeachingPlanFilterConsultContent h3, .wpTeachingPlanFilterConsultContent h4, .wpTeachingPlanFilterConsultContent h5, .wpTeachingPlanFilterConsultContent h6{
    margin-bottom: .6em;
} */
.wpTeachingPlanFilterConsultContent div, .wpTeachingPlanFilterConsultContent span, .wpTeachingPlanFilterConsultContent p {
    font-size: 16px;
}
.wpTeachingPlanFilterConsultContentQuery h5{
    /* color: #1677ff; */
    font-weight: 800;
}
.wpTeachingPlanFilterConsultSearchSec{
    width: 80%;
    max-width: 875px;
    min-width: 875px;
    height: 50vh;
    margin: 10px auto;
    padding: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 3px -1px 10px -1px #00152933;
    border-radius: 3px;
    background-color: #F9F9F9;
}
.wpTeachingPlanFilterConsultTitle h4{
    font-weight: 800;
    color: #001529;
}
.wpTeachingPlanFilterConsultList{
    width: 100%;
    /* border: 1px solid #1677ff; */
    border-radius: 5px;
    padding: 10px;
}
.wpTeachingPlanFilterConsultList ul{
    max-height: 47vh;
    overflow-y: scroll;
    padding: 0 12px!important;
}
/* .wpTeachingPlanFilterConsultList span{
    color: #1677ff;
} */
.wpTeachingPlanFilterConsultListItemMeta{
    flex-wrap: wrap;
    flex-direction: column;
    /* cursor: pointer; */
}
/* .wpTeachingPlanFilterConsultListItemMeta:hover{
    background-color: #dedede;
} */
.wpTeachingPlanFilterConsultCourseDescriptionDiv{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.wpTeachingPlanFilterConsultCourseDescriptionPanel .wpTeachingPlanFilterConsultCourseDescriptionQueryDiv{
    border-bottom: 1px solid #ddd;
}
.wpTeachingPlanFilterConsultCourseDescriptionPanel .wpTeachingPlanFilterConsultCourseDescriptionQueryDiv:last-child{
    border-bottom: initial;
}
.wpTeachingPlanFilterConsultCourseDescriptionItem{
    /* width: 100%; */
    margin: 8px;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: flex-start;
}
.wpTeachingPlanFilterConsultCourseDescriptionItemName {
    width: 240px;
}
.wpTeachingPlanFilterConsultCourseDescriptionItemCategory{
    width: 300px;
}
.wpTeachingPlanFilterConsultCourseDescriptionItemDescription{
    width: 360px;
}
.wpTeachingPlanFilterConsultCourseDescriptionItemPriority{
    width: 145px;
}
.wpTeachingPlanFilterConsultCourseDescriptionItemUpdateBtn{
    width: 100%;
    margin-top: 14px!important;
}
.wpTeachingPlanFilterConsultCourseDescriptionItemUpdateBtn span{
    font-size: 14px;
}
.wpTeachingPlanFilterConsultCourseDescriptionText{
    width: 240px;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
.wpTeachingPlanFilterConsultCourseDescriptionLabel{
    font-weight: 800;
    color: #001529cc;
}
.wpTeachingPlanFilterConsultCourseDescriptionName{
    margin-right: 8px;
    font-weight: 800;
    text-align: left;
    white-space: nowrap;
}
.wpTeachingPlanFilterConsultCourseDescriptionLabelName, .wpTeachingPlanFilterConsultCourseDescriptionLabelCategory, .wpTeachingPlanFilterConsultCourseDescriptionLabelDescription{
    /* flex: 5; */
    width: 100px;
}
.wpTeachingPlanFilterConsultCourseDescriptionLabelPriority{
    /* flex: 4; */
    width: 85px;
}
.wpTeachingPlanFilterConsultDiv{
    display: flex;
    flex-direction: row;
}
.wpTeachingPlanFilterConsultCourseDescriptionItemTitleIdNameDiv{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.wpTeachingPlanFilterConsultIconDiv{
    margin: 8px 4px;
    display: flex;
    justify-content: flex-end;
}
.wpTeachingPlanFilterConsultIcon{
    cursor: pointer;
    margin: 2px 1px 5px 5px!important;
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#F5F5F5;
    background-color:#1677FF;
}
.wpTeachingPlanFilterConsultIcon:hover{
    border: 1px solid #1677FF;
    border-radius: 3px;
    color:#1677FF;
    background-color:#F5F5F5;
}
.wpTeachingPlanFilterConsultCourseDescriptionItemTitleDiv{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}
.wpTeachingPlanFilterConsultCourseDescriptionItemTitleDivUpdate{
    flex-direction: column;
    align-items: flex-start;
}
.wpTeachingPlanFilterConsultCourseDescriptionItemTitle{
    max-width: 315px;
    min-width: 250px;
    /* max-width: 70%; */
}

.wpTeachingPlanFilterConsultCourseDescriptionItemTitleIntro{
    max-width: initial;
    width: 100%;
}
.wpTeachingPlanFilterConsultCourseDescriptionItemTitleDivUpdate{
    align-items: flex-start;
    flex-direction: row;
}
.wpTeachingPlanFilterConsultCourseDescriptionItemTitleIdNameDivUpdate{
    flex-direction: column;
}
.wpTeachingPlanDivInputName{
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1510px) {
    .wpTeachingPlanFilterConsultSearchSec{
        height: 57vh;
    }
}
@media screen and (max-width: 1220px) {
    .wpTeachingPlanFilterConsultCourseDescriptionItemTitleDiv{
        align-items: flex-start;
    }
    .wpTeachingPlanFilterConsultCourseDescriptionText{
        width: initial;
    }
}
@media screen and (max-width: 1106px) {
    .wpTeachingPlanOuterSection{
        justify-content: center;
    }
}
@media screen and (max-width: 1090px) {
    .wpTeachingPlanFilterConsultSearchSec{
        min-width: initial;
    }
}
@media screen  and (max-width: 900px) {
    .wpTeachingPlanFilterConsultCourseDescriptionItemTitleIdNameDiv{
        max-width: 335px;
    }
    .wpTeachingPlanFilterConsultCourseDescriptionItemTitle{
        max-width: 95%;
    }
}
@media screen  and (max-width: 767px) {
    .wpTeachingPlanContent>section{
        margin: 8px 0;
    }
    .wpTeachingPlanH4 {
        margin-top: .3rem;
    }
    .wpTeachingPlanFilterConsultIcon{
        margin: 2px 5px 2px 1px!important;
    }
    .wpTeachingPlanForm{
        /* padding: 10px 20px; */
        padding: 5px; 
    }
    .wpTeachingPlanFilterConsultContent div, .wpTeachingPlanFilterConsultContent span, .wpTeachingPlanFilterConsultContent p {
        font-size: 14px;
    }
    /* .wpTeachingPlanFilterConsultContentQuery{
        min-width: 292px;
    } */
    .wpTeachingPlanFilterConsultContentQuery h5{
        font-size: 18px;
    }
    .wpTeachingPlanFilterConsultSearchSec{
        width: 100%;
        /* min-width: 292px; */
    }
    .wpTeachingPlanFilterConsultList{
        padding: 0px 8px;
    }
    .wpTeachingPlanFilterConsultCourseDescriptionDiv{
        width: 100%;
        /* flex-direction: column; */
    }
    .wpTeachingPlanFilterConsultCourseDescriptionItem {
        margin: 8px 4px;
        /* width: 100%; */
        /* justify-content: flex-start; */
    }
    .wpTeachingPlanFilterConsultCourseDescriptionItemUpdateBtn{
        justify-content: center;
    }
    .wpTeachingPlanContent{
        max-width: initial;
    }
    .wpTeachingPlanSection{
        /* min-width: 300px; */
        padding: 0;
    }
    /* .wpTeachingPlanSectionCourseIdName{
        padding: 0 20px 0;
    } */
    .wpTeachingPlanSectionAddActivity{
        padding: 0.6rem 0 0;
    }
    /* .wpTeachingPlanDivActivity{
        min-width: 292px;
    } */
    .wpTeachingPlanDelIcon{
        margin: 5px!important;
    }
    .wpTeachingPlanInputPlace{
        width: 100%;
        min-width: 252px;
    }
    .wpTeachingPlanTimePicker{
        width: 100%;
        min-width: 252px;
    }
    .wpTeachingPlanFilterConsultCourseDescriptionItemTitleDiv{
        flex-direction: column-reverse;
    }
    /* .wpTeachingPlanSpaceTime{
        min-width: 280px;
    } */
    .wpTeachingPlanFilterConsultSearchSec{
        height: 100%;
        padding: 18px 12px;
    }
}
@media screen  and (max-width: 540px) {
    /* .wpTeachingPlanForm{
        padding: 5px;
    } */
    .wpTeachingPlanInput{
        width: 100%;
    }
    .wpTeachingPlanInputName{
        width: 100%;
    }
    .wpTeachingPlanFilterConsultCourseDescriptionText{
        max-width: 125px;
    }
}
@media screen  and (max-width: 431px) {
    /* .wpTeachingPlanDatePicker{
        margin-bottom: 8px;
    } */
}
